import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/costos.css';
import { FastBackwardOutlined, LeftOutlined } from '@ant-design/icons';

import img_titulo from '../../assets/images/reunion_anual/TITULOS/titulo_costos.png';

import costo_1 from '../../assets/images/anual_2024/home/convencionista.png';
import costo_4 from '../../assets/images/anual_2024/home/acompañante.png';
import costo_2 from '../../assets/images/anual_2024/home/cuentamxn.png';
import costo_3 from '../../assets/images/anual_2024/home/cuentausd.png';


const Costos = (props) => {
    const { idioma } = props;
    return (
        <Row justify="center" className='back_costos_sec_'  style={{paddingTop:'3%'}}>
            <Col xs={24} md={21}>
                <p className="textos_titulos_secciones color_azul">COSTOS</p><br />
            </Col>
            <Col xs={24}>
                <Row align={"bottom"} justify={"center"}>
                <Col xs={12} md={10}>
            <img src={costo_1} style={{width:'90%'}} />
                </Col>
                <Col xs={12} md={10}>
            <img src={costo_4} style={{width:'90%'}} />
                </Col>
                <Col xs={20} md={18}><br />
                    <p className='color_azul cuadro_texto'>
                    <span className='cuadro_texto_1'>Promoción especial por corporativo asociado AMPIP:</span><br />
                    {/*Un acceso sin costo para un acompañante, registrado y pagado junto con el<br /> convencionista con quien acude, hasta antes del 18 de octubre de 2024.*/}
                    <ul style={{textAlign:'center', listStyle:'inside', marginBottom:'0px'}}>
                        <li><span style={{textDecoration:'line-through'}}>10% de descuento para corporativos que adquieran 4 o más entradas, y sean <br />pagadas juntas entre el 1 y el 30 de agosto de 2024.</span> <span className='color_azul_claro'>(Gracias por participar, promoción concluida.)</span></li>
                        <li><span style={{textDecoration:'line-through'}}>Un acceso en cortesía para un acompañante**, registrado y pagado junto con el convencionista<br /> con quien acude, a más tardar el 18 de octubre de 2024. Solo aplica una cortesía por corporativo.</span><span className='color_azul_claro'>(Gracias por participar, promoción concluida.)</span></li>
                    </ul>
                    </p>
                    <small>** se entiende por "acompañante" a la pareja o familiar del convencionista. No aplica el termino "acompañante" para personal del mismo corporativo.</small>
                </Col>
            
                </Row>
            </Col>
            <Col span={18}>
            <span className='no_ver_chico'><br /></span><br />
                <p className="texto_costos color_azul">
                    <strong>Términos y condiciones</strong>
                    <lu className="color_azul">
                    <li>Incluye kit de bienvenida y acceso a todos los eventos incluidos en el programa. </li>
<li>No incluye transporte aéreo, ni hospedaje, ni transporte local.</li>
<li>La fecha límite para registro y pago de entradas es: jueves 31 de octubre de 2024. </li>
<li>No aplican reembolsos en caso de cancelación.</li>
<li>No aplican cambios para eventos futuros.</li>
                    </lu>
                </p><br />
                <span className='no_ver_chico'><br /></span>
            </Col>
            
            <Col span={24} className='back_datos' style={{backgroundColor:'#ffffff'}}>
                <br />
                <Row justify="center">
                    <Col xs={22}>
                        <p className='datos_sub color_azul bold_font'><strong>DATOS BANCARIOS</strong></p>
                        <p className='transferen_text color_azul'>Transferencia electrónica, pago en una sola exhibición</p>
                        <br />
                    </Col>
                </Row>
                <Row justify="center">
                    <Col xs={20} md={10} className=''>
                    <img src={costo_2} style={{width:'90%'}} />
                    </Col>
                    <Col xs={0} md={0}>
                        <br /><br />
                    </Col>
                    <Col xs={20} md={10} className=''>
                    <img src={costo_3} style={{width:'90%'}} />
                    </Col>
                </Row>
                <br />
            </Col>
        </Row>
    )
}
export default Costos;