import React, { useState, useContext, useEffect, useRef } from "react";
import { Row, Col, Form, Input, Button, Modal, Select, Spin, message, notification } from "antd";
import Firebase from "../../utils/firebaseConfig";
import { Auth } from "../../utils/AuthContext";
import Errores from './Errores'
import '../../assets/css/registro.css';
import { permitir_entrada } from '../../utils/ConexionServidor';

const app = new Firebase();

let function_recaptcha;


let recaptcha_phone;


const nombre_webinar = 'segunda-reunion';

const mensaje = (tipo, titulo, texto) => {

    notification[tipo]({
        message: titulo,
        description: texto,
    });

}
const Login = ({ history, onResponse }) => {

    let mi_correo = '';

    const { usuario, datos } = useContext(Auth);
    const [error, seterror] = useState('');
    const [correo_recuperar, setCorreoRecuperar] = useState('');
    const [ingresar_codigo, setIngresarCodigo] = useState(false);
    const [cargando_login, setCargandoLogin] = useState(false);

    const [form] = Form.useForm();

    const ref_codigo = useRef(null);
    let key = '';
    const ref_form_login = useRef(null);

    useEffect(() => {

        console.log('usuario');
        console.log(datos);

        if (usuario) {
            //alert('usuario registrado');
        }
    }, [history, usuario]);

    const onFinish = async values => {

        setCargandoLogin(true);
        values.nombre_curso = nombre_webinar;

        permitir_entrada(values).then((data) =>{
            //key = data.key;
            switch(data.status){
                case 0:
                    app.app.auth().signInAnonymously().then(()=>{
                        mensaje('success', 'Bienvenido', '');
                        console.log('data',data.data);
                        app.escribir(nombre_webinar + '/usuarios', undefined, data.data, ()=>{});
                        onResponse();
                    }).catch((error) => {
                        mensaje('error', 'Error al cargar usuario, inténtalo más tarde', '');
                    });
                    
                    break;
                    default: 
                    mensaje('warning', 'Datos incorrectos', '');
                    onResponse();
                    break;
            }
            setCargandoLogin(false);
        }).catch((e)=>{
            setCargandoLogin(false);
            mensaje('error', 'Error de conexión', '');
        });




    };


    useEffect(() => {


        recaptcha_phone = window.recaptchaVerifier = new app.app.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': function (response) {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                //ref_form_login.current.submit();
            }
        });;
    }, []);

    const colocarCodigo = () => {
        setCargandoLogin(true);
        console.log(ref_codigo.current.state.value);

        function_recaptcha.confirm(ref_codigo.current.state.value).then(function (result) {
            // User signed in successfully.
            var user = result.user;
            onResponse();
            setCargandoLogin(false);
            // ...
        }).catch(function (error) {
            // User couldn't sign in (bad verification code?)
            // ...
            mensaje('error', 'Código incorrecto');
            setCargandoLogin(false);
        });


    }

    return (
        <Row className="contenedor_registro">
            <Col span={24}>
                <Spin spinning={cargando_login}>
                    {!ingresar_codigo ?
                        (


                            <Form className="login-form"
                                onFinish={onFinish}
                                ref={ref_form_login}
                                layout="vertical"
                                initialValues={{
                                    prefix: '+52',
                                }} >

                                <Form.Item
                                    name="correo"
                                    label={<span style={{ color: '#ffffff' }}>Correo electrónico</span>}
                                    rules={[
                                        {
                                            type: 'email',
                                            required: true,
                                            message: 'Ingresa tu correo!',
                                        },
                                    ]}
                                    onChange={(e) => { setCorreoRecuperar(e.target.value); }}
                                >
                                    <Input style={{ backgroundColor: '#083280' }} />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label={<span style={{ color: '#ffffff' }}>Apellido <small>(Utiliza sólo minúsculas, sin acentos y una sola palabra)</small></span>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa tu apellido!',
                                        },
                                    ]}
                                    onChange={(e) => { setCorreoRecuperar(e.target.value); }}
                                >
                                    <Input style={{ backgroundColor: '#083280' }} />
                                </Form.Item>

                                {error ? <Form.Item><Errores mensaje={error} /></Form.Item> : null}
                                <Form.Item>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <br />
                                            <button id="sign-in-button" className="boton_recibir_codigo" htmlType="submit">Ingresar</button>
                                            <br /><br />
                                            <a target="_blank" href={"https://api.whatsapp.com/send?phone=525549826570&text=Tengo%20problemas%20con%20mi%20inicio%20de%20sesi%C3%B3n%20en%20AMPIP."} style={{textDecoration:'none', color:'#ffffff'}}>Tengo problemas con mi inicio de sesión</a>
                                        </Col>
                                    </Row>

                                </Form.Item>

                            </Form>

                        )
                        :
                        (
                            <Row>
                                <Col span={0}>
                                    <p style={{ textAlign: 'center', color: '#fe633f' }}><br />Ingresa el código que llego a tu teléfono</p>
                                </Col>
                                <Col span={0}>
                                    <Input style={{ width: '100%' }} ref={ref_codigo} />
                                </Col>
                                <Col span={0}>
                                    <Button className="boton_recibir_codigo" style={{ width: '100%' }} onClick={colocarCodigo}>Verificar</Button>
                                </Col>
                            </Row>
                        )
                    }
                </Spin>
            </Col>




        </Row>

    );
}

export default Login;
